import { GetStaticPaths, GetStaticProps } from 'next';
import React, { FC, useEffect } from 'react';

import LoadingIcon from '@/components/icons/loading-icon';
import Layout from '@/components/layout';
import getPageStaticPaths from '@/lib/get-page-static-paths';
import getPagesCommonProps from '@/lib/get-pages-common-props';
import { isPreviewModeEnabled as checkIfPreviewModeIsEnabled } from '@/lib/preview';
import { wrapper as StoreWrapper } from '@/store';
import { setPageTheme } from '@/store/slices/global';
import { PageProps } from '@/types/views/generic';

interface EnergyOneProps
  extends Pick<PageProps, 'navigation' | 'isPreviewModeEnabled' | 'theme'> {
  includeUrl: string;
}

const EONE_INCLUDE_SCRIPT_ID = 'eone-include-script';

const EnergyOne: FC<EnergyOneProps> = ({
  navigation,
  isPreviewModeEnabled,
  includeUrl,
}) => {
  useEffect(() => {
    // Load and mount the Energy One app
    const scriptElement = document.createElement('script');
    scriptElement.src = includeUrl;
    scriptElement.id = EONE_INCLUDE_SCRIPT_ID;
    document.head.appendChild(scriptElement);

    return () => {
      document.getElementById(EONE_INCLUDE_SCRIPT_ID)?.remove();
    };
  }, [includeUrl]);

  return (
    <>
      <div id="modals" />
      <div id="tooltip" className="relative z-20" />
      <div id="portal-root" />

      <Layout
        navigation={navigation}
        isPreviewModeEnabled={isPreviewModeEnabled}
      >
        <div id="energy-one">
          <div id="root" className="one-app-root teone:text-black">
            <div className="flex justify-center items-center">
              <LoadingIcon id="energy-one-loading-icon" className="w-16 h-16" />
            </div>
          </div>
        </div>
        {/* Because wrong fonts are loaded by the app itself */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="crossorigin"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@1,700&display=swap"
          rel="stylesheet"
        />
      </Layout>
    </>
  );
};

export const getStaticProps: GetStaticProps<EnergyOneProps> =
  StoreWrapper.getStaticProps((store) => async (ctx) => {
    try {
      const { navigation } = await getPagesCommonProps(store);

      store.dispatch(setPageTheme('eone'));

      const props = {
        navigation,
        isPreviewModeEnabled: checkIfPreviewModeIsEnabled(ctx),
        // cannot be undefined, since it's validated at server start
        includeUrl: process.env.ENERGY_ONE_INCLUDE_URL!,
      };

      return {
        props,
        revalidate: Number(process.env.PAGE_VALIDITY_PERIOD),
      };
    } catch (error) {
      console.error(error);
      // TODO (ECH-3457): handle error somehow
      throw error;
    }
  });

export const getStaticPaths: GetStaticPaths = getPageStaticPaths;

export default EnergyOne;
